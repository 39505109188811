<template>
  <div class="profession" ref="profession">
    <!-- <el-input
      placeholder="输入关键字进行过滤"
      v-model="filterText">
    </el-input> -->
    <el-tree
      class="filter-tree"
      node-key="id"
      :data="list"
      :props="defaultProps"
      :default-expand-all="true"
      :filter-node-method="filterNode"
      show-checkbox
      @check="checkHandler"
      ref="tree"
    >
    </el-tree>
    <!-- <p class="tip">温馨提示：最多选择五个大类！</p> -->
  </div>
</template>

<script>
import getProfession from "@/api/profession";

export default {
  name: "Profession",
  watch: {
    list: {
      handler(newval) {
      },
      deep: true,
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    gradeType(val) {
      this.getProfessionList();
    },
    changeFlag(val) {
      if (val.toString().startsWith("true")) {
        this.filterText = "";
      }
    },
  },
  props: {
    gradeType: String,
    changeFlag: String,
    list:{
      type:Array
    },
  },
  data() {
    return {
      filterText: "",
      professionList: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      currentCheckedNoded: null,
      canCheckedAllkeys: null,
      group: [],
      result: {},
      historyData:null
    };
  },
  mounted() {
    // this.getProfessionList();
  },
  methods: {
    snapshoot(){
      this.historyData = {
        currentCheckedNoded:this.currentCheckedNoded?JSON.parse(JSON.stringify(this.currentCheckedNoded)):null,
        canCheckedAllkeys:this.canCheckedAllkeys?JSON.parse(JSON.stringify(this.canCheckedAllkeys)):null,
        group:JSON.parse(JSON.stringify(this.group)),
        result:JSON.parse(JSON.stringify(this.result))     
      }
    },
    recover(){
      this.currentCheckedNoded = this.historyData.currentCheckedNoded
      this.canCheckedAllkeys = this.historyData.canCheckedAllkeys
      this.group = this.historyData.group
      this.result = this.historyData.result
      const allKeys = this.result.allKeys || [];
      const allRoot = this.group.map((item) => item.id);
      const newKeys = allKeys.filter((id) => !allRoot.includes(id));
      this.$refs.tree.setCheckedKeys(newKeys);
    },
    filterMajor(majorName) {
        let that = this 
      this.$nextTick(() => {
        const items = $(that.$refs.profession)
          .find(".el-tree")
          .children();
        const showIndex = that.list.findIndex(
          (item) => item.name === majorName
        );
        for (let i = 0; i < items.length; i++) {
          if (showIndex === i) {
            $(items[i]).css("display", "block");
          } else {
            $(items[i]).css("display", "none");
          }
        }
      });
    },
    checkAll(id, selected) {
      this.getCheckedNodes();
      const { allKeys } = this.result;
      const allRoot = this.group.map((item) => item.id);
      const setRoot = this.group.find((item) => item.id === id);
      let { list } = setRoot;
      if (selected) {
        const newAdd = list.filter(
          (id) => !allKeys.includes(id) && !allRoot.includes(id)
        );
        const existKeys = allKeys.filter((id) => !allRoot.includes(id));
        this.$refs.tree.setCheckedKeys([...existKeys, ...newAdd]);
      } else {
        const all = [...list, id];
        const newKeys = allKeys.filter((id) => !all.includes(id));
        this.$refs.tree.setCheckedKeys(newKeys); //通过 keys 设置目前勾选的节点，使用此方法必须设置 node-key 属性
      }
    },
    dataGroup() {
      const arr = [];
      this.list.map((root) => {
        const list = [];
        const { id, name } = root;
        this.revers(root, (node) => {
          list.push(node.id);
        });
        arr.push({
          id,
          name,
          list,
          selected: 0,
        });
      });
      this.group = arr;
    },
    revers(root, callback) {
      const { children } = root;
      if (children && children.length) {
        children.map((childRoot) => {
          this.revers(childRoot, callback);
        });
      } else {
        callback && callback(root);
      }
    },
    checkedStatus(checkedList) {
      this.dataGroup();
      this.group.map((item) => {
        const { list } = item;
        let len = 0;
        list.map((id) => {
          checkedList.includes(id) && len++;
        });
        item.selected = len ? (len === list.length ? 1 : 2) : 0;
        const updateRootStatus = this.list.find((node) => node.id === item.id);
        updateRootStatus && (updateRootStatus.selected = item.selected);
      });
    },
    getCheckedNodes() {
      //返回目前被选中的节点所组成的数组
      const result = {
        allNodes: this.$refs.tree.getCheckedNodes(false, true), //获父节点
        childNodes: this.$refs.tree.getCheckedNodes(true, false),
      };
      result.checked = result.childNodes.map((item) => item.checked);
      result.allKeys = result.allNodes.map((item) => item.id);
      result.allNames = result.allNodes.map((item) => item.name);
      result.childKeys = result.childNodes.map((item) => item.id);
      result.childNames = result.childNodes.map((item) => item.name);
      result.parentKeys = result.allKeys.filter(
        (item) => result.childKeys.indexOf(item) === -1
      );
      result.parentNames = result.allNodes
        .filter((item) => result.parentKeys.indexOf(item.id) !== -1)
        .map((item) => item.name);
      this.currentCheckedNoded = result;
      this.checkedStatus(result.allKeys);
      result.group = this.group;
      this.result = result;
      return result;
    },
    checkHandler() {
      this.getCheckedNodes();
    //   if (this.currentCheckedNoded.parentKeys.length === 5) {
        this.canCheckedAllkeys = this.currentCheckedNoded.childKeys;
    //   } else if (this.currentCheckedNoded.parentKeys.length > 5) {
        // this.$message.messageError("最多选择五个大类！");
        this.$refs.tree.setCheckedKeys(this.canCheckedAllkeys);
    //   }
      this.$emit("majorChange");
    },
    // 获取专业列表
    getProfessionList() {
      const params = {
        type: JSON.parse(localStorage.getItem("user")).studentType,
        gradeType: this.gradeType,
        userId: JSON.parse(localStorage.getItem("user")).userId,
      };
      getProfession(params).then((res) => {
        if (res.code === 0) {
          this.professionList = res.data;
        }
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
  },
};
</script>

<style lang="scss" scoped>
// /deep/ .el-tree-node__content{
//     padding-left: 0px;
//     width: 180px;
// }
.el-tree {
  max-height: 200px;
  overflow-y: auto;
  padding-right: 3px;

  /deep/ .el-tree-node {
    margin-bottom: 10px;
    border-radius: 4px;
  }

  /deep/ .el-tree-node__content {
    background-color: #f5f5f5;
    /deep/ .el-tree-node__label {
      font-size: 14px;
    }
  }

  /deep/ .el-tree-node__children {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    background-color: #f5f5f5;
  }
}
.tip {
  font-size: 12px;
  text-align: left;
  color: #0049ef;
}
</style>
