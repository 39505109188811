import request from '@/utils/request';

// 获取专业列表
export default function getProfession(params) {
  return request({
    url: '/profession/getTree',
    method: 'get',
    params,
  });
}
