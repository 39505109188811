<template>
  <div class="home">
    <banner />
    <base-box box-width="95%" top-bar-label="方案查询">
      <div slot="content" class="content">
        <div class="abox" style="width: 100%">
          <!-- <div class="syy" style="display:flex;border:1px solid blue;"> -->
            <div class="content-box syd" style="line-height: 60px">
              <label class="boldFont1" style="margin-right: 28px">生源地</label>
              <div class="content-list-item">山东</div>
            </div>
            <div class="content-box syd" style="line-height: 60px;">
              <label class="boldFont1" style="margin-right: 28px"
                >录取批次</label
              >
              <div>
                <template>
                  <el-radio v-model="radio" label="2" @change="checkRadio"
                    >本科</el-radio
                  >
                  <el-radio v-model="radio" label="1" @change="checkRadio"
                    >专科</el-radio
                  >
                </template>
              </div>
            </div>
          <!-- </div> -->
            <div class="ppt">
              <div
              class="content-box kscj"
              style="width: 100%; line-height: 60px"
            >
              <label class="boldFont1" style="margin-right: 28px"
                >考试成绩</label
              >
              <div class="content-list-item">文化课：{{ cultureGrade }}分</div>
              <div v-if="studentType !== 1" class="content-list-item">
                专业课：{{ majorGrade }}分
              </div>
            </div>
          </div>
        </div>

        <div class="content-box" style="width: 100%; line-height: 60px">
          <label class="boldFont" style="margin-right: 48px">专业选择</label>
          <div class="profession-content">
            <div class="majorClass" v-if="this.radio == '2'">
              <div
                class="marjorName"
                v-for="(item, index) in gradeList"
                :key="index"
              >
                <!-- <el-checkbox  分开控制
                  :disabled="
                    majorSelectedCount >= majorSelectedMax &&
                      item.selected === 0
                  "
                  @change="
                    (val) => {
                      checkAll(val, item);
                    }
                  "
                  :indeterminate="item.selected === 2"
                  :value="item.selected === 1"
                />
                <span class="text" @click="modifyProfession(true, item)">{{
                  item.name
                }}</span> -->
                  <!-- :disabled="
                    majorSelectedCount >= majorSelectedMax &&
                      item.selected === 0
                  " -->
                  <el-checkbox
                  @click.native.prevent="modifyProfession(true, item)"
                  :indeterminate="item.selected === 2"
                  :value="item.selected === 1"
                />
                <span class="text" @click="modifyProfession(true, item)">{{
                  item.name
                }}</span>
              </div>
            </div>
            <div class="majorClass" v-if="this.radio == '1'">
              <div
                class="marjorName"
                v-for="(item, index) in gradeList"
                :key="index"
              >
                <!-- <el-checkbox  分开控制
                  :disabled="
                    majorSelectedCount >= majorSelectedMax &&
                      item.selected === 0
                  "
                  @change="
                    (val) => {
                      checkAll(val, item);
                    }
                  "
                  :indeterminate="item.selected === 2"
                  :value="item.selected === 1"
                />
                <span class="text" @click="modifyProfession(true, item)">{{
                  item.name
                }}</span> -->
                  <!-- :disabled="
                    majorSelectedCount >= majorSelectedMax &&
                      item.selected === 0
                  " -->
                <el-checkbox
                  @click.native.prevent="modifyProfession(true, item)"
                  :indeterminate="item.selected === 2"
                  :value="item.selected === 1"
                />
                <span class="text" @click="modifyProfession(true, item)">{{
                  item.name
                }}</span>
              </div>
            </div>
            <!-- <p v-if="profession.length === 0" style="font-size: 14px;">请选择专业</p> -->
            <!-- <div v-for="(item, index) in profession"
              :key="`profession-${index}`"
              class="content-list-item"
            >
              {{ item.name }}
            </div> -->
          </div>
          <!-- <el-button type="primary" style="width: 128px;" @click="modifyProfession">
            {{ profession.length === 0 ? '请选择专业' : '更改专业' }}
          </el-button> -->
        </div>
        <div class="content-box" style="width: 100%; line-height: 60px">
          <label class="boldFont" style="margin-right: 48px">院校驻地</label>
          <div class="profession-content" style="width: 88% !important">
            <el-checkbox-group v-model="checkList" style="text-align: justify">
              <el-checkbox
                v-for="(item, index) in RegionList"
                :label="item"
                :key="index"
              />
            </el-checkbox-group>
          </div>
          <!--          <el-button type="primary" style="width: 128px;" @click="modifyProfession">-->
          <!--            请选择驻地-->
          <!--          </el-button>-->
        </div>
        <p
          v-if="this.remain88==0 && !addRecord && isshow"
          class="tip"
          style="width: 100%; color: red; text-align: left"
        >
          温馨提示：您当前的查询次数为零，若需要查询完整方案请前往我的账户进行充值。
        </p>
        <p
          v-if="this.remain88 != 0 && !addRecord && isshow"
          class="tip"
          style="width: 100%; color: #409eff; text-align: left"
        >
          温馨提示：请确定您的查询条件准确，确认查询并生成方案后会扣除相应的查询次数。
        </p>
        <!-- <p
          class="tip"
          v-if="!addRecord"
          style="width: 100%; color: #409eff; text-align: left"
        >
          温馨提示：请确定您的查询条件准确，确认查询并生成方案后会扣除相应的查询次数。
        </p> -->
        <el-button
          v-if="!addRecord"
          class="bottom-btn"
          type="primary"
          @click="search"
        >
          查询方案
        </el-button>
        <el-button
          v-else
          class="bottom-btn"
          type="primary"
          @click="handleScheme"
          >查询</el-button
        >
      </div>
    </base-box>
    <base-box
      v-if="schemeShow"
      id="recommend"
      box-width="95%"
      box-height="564px;"
      top-bar-label="推荐方案"
    >
      <div slot="content" class="content">
        <div
          v-if="ifDisplayAll"
          style="width: 100%; margin-bottom: 20px; text-align: right"
        >
          <el-button type="primary" @click="goProgramme">编辑</el-button>
          <el-button type="primary" @click="handleExport('excel')"
            >导出Excel</el-button
          >
          <el-button type="primary" @click="handleExport('pdf')"
            >导出PDF</el-button
          >
        </div>
        <div
          class="table"
          style="width: 100%; margin-bottom: 20px"
        >
          <el-table
            :data="schemlTable"
            @selection-change="handleSelectionChange"
            :header-cell-style="{'color':'#409EFF','padding': '0px 0px'}"
            stripe
          >
            <el-table-column
              v-if="addRecord"
              type="selection"
              width="55"
            ></el-table-column>
             <el-table-column
              type="index"
              label="序号"
              align="center"
            />
            <el-table-column
              prop="majorId"
              label="专业代号"
              width="60px"
              align="center"
            />
            <el-table-column prop="majorName" label="专业名称" align="center" />
            <el-table-column
              prop="academyId"
              label="院校代号"
              width="60px"
              align="center"
            />
            <el-table-column prop="academy" label="院校名称" align="center" />
            <el-table-column
              prop="courseDemand"
              label="选科要求"
              width="115px;"
              align="center"
            />
            <el-table-column
              prop="nature"
              label="学校性质"
              width="95px"
              align="center"
            />
            <el-table-column
              prop="schoolAddress"
              label="学校地址"
              align="center"
            />
            <el-table-column
              prop="yearLimit"
              label="学制"
              width="65px"
              align="center"
            />
            <el-table-column prop="" label="普通类" align="center">
              <el-table-column
                prop="planNum"
                label="计划数"
                width="50px"
                align="center"
              />
              <!-- <el-table-column
                prop="castArchivesNum"
                label="投档计划数"
                align="center"
              />
              <el-table-column prop="launchNum" label="投出数" align="center" /> -->
              <el-table-column
                prop="annualFee"
                label="年收费 （元）"
                width="65px"
                align="center"
              />
              <el-table-column
                prop="lowestMark"
                label="参考分"
                width="65px"
                align="center"
              />
              <el-table-column
                prop="lowestRank"
                label="最低位次"
                align="center"
                width="95px"
              />
            </el-table-column>
          </el-table>
        </div>
        <template v-if="addRecord">
          <el-button class="bottom-btn" type="primary" @click="handleAdd"
            >确认插入</el-button
          >
        </template>
        <template v-if="!ifDisplayAll && !addRecord">
          <div class="tip" style="width: 100%; color: red;display:flex;">
            <div class="xinxin">*</div>
            <p>前面呈现的学校录取率较低，请参照完整方案进行报考<p/>
          </div>
          <!-- <p class="tip" style="width: 100%; margin-top: 10px; color: red">
            <span>*</span>
            <span>前面呈现的学校录取率较低，请参照完整方案进行报考</span>
          </p> -->
          <el-button
            v-if="schemlTable.length >= 10"
            class="bottom-btn"
            type="primary"
            @click="handleMore"
            >显示完整方案</el-button
          >
        </template>
      </div>
    </base-box>
    <!--    <floatAd :class="float && 'float-ad'" />-->
    <div class="foot-police">
      <img src="../../assets/images/police.png" />
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
        >鲁ICP备2021009162号&nbsp;&nbsp;工业和信息化部备案管理系统网站</a
      >
    </div>
    <div v-show="professionDialogVisible" class="ppo">
      <base-dialog
        :baseDialogVisible="changeTrue"
        baseDialogWidth="38%"
        @closeDialog="closeProfessionDialog"
        :modalAppend="false"
      >
        <span slot="dialog-title">专业列表</span>
        <div>
          <profession
            @majorChange="majorChange"
            ref="profession"
            v-bind:gradeType="gradeType"
            v-bind:list="gradeList"
            v-bind:changeFlag="changeFlag"
          />
        </div>
        <span class="dialog-footer" slot="dialog-footer">
          <el-button @click="cancleH">取消</el-button>
          <el-button type="primary" @click="checkProfession">确定</el-button>
        </span>
      </base-dialog>
    </div>

    <div v-if="checkMoreDialogVisible">
      <base-dialog
        :baseDialogVisible="checkMoreDialogVisible"
        :baseDialogTitleShow="false"
        @closeDialog="closeCheckMoreDialog"
        :modalAppend="false"
      >
        <div>
          <img
            src="@/assets/images/icon_tip@3x.png"
            alt
            style="width: 100px; height: 100px"
          />
          <p>
            您的刷新点次数为 {{ remainTimes }}，{{
              remainTimes > 0 ? "是否确认刷新?" : "请购买刷新点次数"
            }}
          </p>
        </div>
        <span class="dialog-footer" slot="dialog-footer">
          <el-button @click="checkMoreDialogVisible = false">取消</el-button>
          <el-button v-if="remainTimes > 0" type="primary" @click="checkMore"
            >确认</el-button
          >
          <el-button v-else type="primary" @click="toRecharge"
            >去购买</el-button
          >
        </span>
      </base-dialog>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import * as ApiScheme from "@/api/programme";
import getProfession from "@/api/profession";
import { getUserInfoById, getUserRemainingNumber } from "@/api/user";
import BaseDialog from "@/components/BaseDialog/index.vue";
import BaseBox from "@/components/BaseBox/index.vue";
import Banner from "./components/Banner.vue";
// import FloatAd from './components/Swiper.vue';
import Profession from "./components/Profession.vue";
import { setUserName, getUserName, getUserId} from "../../utils/auth";

export default {
  name: "Home",
  components: {
    BaseDialog,
    Banner,
    BaseBox,
    // FloatAd,
    Profession,
  },
  data() {
    return {
      changeTrue: false,
      remain88:null,
      screenWidth: "",
      screenHeight: "",
      selectStatus: "",
      currentRecordId: null,
      currentMajorName: null,
      currentScore: null,
      studentType: null,
      float: false,
      schemeShow: false,
      remainTimes: 0,
      checkMoreDialogVisible: false,
      professionDialogVisible: false,
      profession: [],
      cancleProfession: [], //取消勾选
      ifDisplayAll: false, // false为只查十条，true展示完整方案
      schemlTableNotAll: [],
      schemlTableAll: [],
      schemlTable: [],
      checkedNodes: null,
      cancleNodes: null, //取消勾选
      cultureGrade: 0,
      majorGrade: 0,
      radio: "2",
      gradeType: "",
      gradeList: [],
      changeGradeFlag: true,
      changeFlag: "true",
      checkList: [],
      RegionList: [], // 院校驻地
      addRecord: false, // false为正常逻辑 true为从插入记录跳过来的
      isshow: false,
      selectedRecordList: [], // 选中要添加的记录
      defaultChecked: [],
      majorSelectedMax: 5,
      majorSelectedCount: 0,
      tempItem: null, // 缓存选中的值
      tempChecked: "",
      historyData: null,
    };
  },
  // watch() {
  //   window.onresize = () => {
  //     return (() => {
  //       this.screenWidth = document.body.clientWidth;
  //       this.screenHeight = document.body.clientHeight;
  //     })();
  //   };
  // },
  mounted() {
    this.getTimes();
    // eslint-disable-next-line no-unused-expressions
    localStorage.getItem("goHomeAddRecord")
      ? (this.addRecord = true)
      : (this.addRecord = false);
    localStorage.removeItem("goHomeAddRecord");
    console.info(localStorage.getItem("goHomeAddRecord"), this.addRecord);
    this.getUserInfo();
    this.getRegionList();
    // this.studentType = JSON.parse(localStorage.getItem('user')).studentType;
    window.addEventListener("scroll", this.handleScrollFloatAd);
    // 监听窗口变化
    this.screenWidth = document.body.clientWidth;
    console.log("宽", this.screenWidth);
    this.screenHeight = document.body.clientHeight;
    console.log("高", this.screenHeight);
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
        this.screenHeight = document.body.clientHeight;
      })();
    };
            this.changeTrue = false;

  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScrollFloatAd);
  },
  methods: {
    // 查询次数
    getTimes() {
      const params = {
        userId: getUserId(),
      };
      getUserRemainingNumber(params).then((res) => {
        if (res.code === 0) {
          this.remain88 = res.data ? res.data.usableLimit : 0;
          console.log("查询次数",this.remain88)
          this.isshow = true  //让状态一致
        } else {
          this.$message.messageError(res.message);
        }
      });
    },
    snapshoot() {
      this.historyData = {
        gradeList: JSON.parse(JSON.stringify(this.gradeList)),
        majorSelectedCount: this.majorSelectedCount,
        majorSelectedMax: this.majorSelectedMax,
        checkedNodes: JSON.parse(JSON.stringify(this.checkedNodes)),
        profession: JSON.parse(JSON.stringify(this.profession)),
      };
      this.$refs.profession.snapshoot();
    },
    recover() {
      this.gradeList = this.historyData.gradeList;
      this.majorSelectedCount = this.historyData.majorSelectedCount;
      this.majorSelectedMax = this.historyData.majorSelectedMax;
      this.checkedNodes = this.historyData.checkedNodes;
      this.profession = this.historyData.profession;
      this.$refs.profession.recover();
    },
    checkAll(selected, item) {
      const { id } = item;
      this.selectStatus = selected;
      this.$refs.profession.checkAll(id, selected);
      this.checkProfession();
      this.gradeList = JSON.parse(JSON.stringify(this.gradeList));
      this.majorChange();
    },
    majorChange() {
      let majorSelectedCount = 0;
      this.gradeList.map((item) => {
        item.selected !== 0 && item.level === 1 && majorSelectedCount++;
      });
      this.majorSelectedCount = majorSelectedCount;
    },
    checkRadio(value) {
      this.changeTrue = false;
      this.profession = [];
      this.changeGradeFlag = true;
      this.modifyProfession(false);
      // this.changeFlag = true;
    },
    handleExport(type) {
      const params = {
        userId: JSON.parse(localStorage.getItem("user")).id,
        recordId: this.currentRecordId,
      };
      this.currentMajorName = this.profession
        .map((item) => item.name)
        .join("_");
      if (this.studentType === 1) {
        this.currentScore = this.cultureGrade;
      } else {
        this.currentScore = `文化课${this.cultureGrade}分_专业课${this.majorGrade}`;
      }
      if (type === "pdf") {
        ApiScheme.exportSchemePDF(params).then((res) => {
          const blob = new Blob([res], { type: "application/pdf" });
          try {
            if ("msSaveOrOpenBlob" in navigator) {
              window.navigator.msSaveOrOpenBlob(
                new Blob([blob]),
                `${getUserName()}_${this.currentMajorName}_${
                  this.currentScore
                }分_推荐方案.pdf`
              );
            } else {
              const url = window.URL || window.webkitURL || window.moxURL;
              const blobUrl = url.createObjectURL(blob);
              const a = document.createElement("a");
              a.style.display = "none";
              a.download = `${getUserName()}_${this.currentMajorName}_${
                this.currentScore
              }分_推荐方案.pdf`;
              a.href = blobUrl;
              a.click();
            }
          } catch (e) {
            this.$message.messageError("导出失败，请重试");
          }
        });
      } else if (type === "excel") {
        ApiScheme.exportSchemeExcel(params).then((res) => {
          const blob = new Blob([res], { type: "application/vnd.ms-excel" });
          try {
            if ("msSaveOrOpenBlob" in navigator) {
              window.navigator.msSaveOrOpenBlob(
                new Blob([blob]),
                `${getUserName()}_${this.currentMajorName}_${
                  this.currentScore
                }分_推荐方案.xlsx`
              );
            } else {
              const url = window.URL || window.webkitURL || window.moxURL;
              const blobUrl = url.createObjectURL(blob);
              const a = document.createElement("a");
              a.style.display = "none";
              a.download = `${getUserName()}_${this.currentMajorName}_${
                this.currentScore
              }分_推荐方案.xlsx`;
              a.href = blobUrl;
              a.click();
            }
          } catch (e) {
            this.$message.messageError("导出失败，请重试");
          }
        });
      }
    },
    search() {
      console.info("searchFun", this.addRecord);
      this.ifDisplayAll = false;
      this.handleScheme();
    },
    handleScrollFloatAd() {
      const top = document.documentElement.scrollTop;
      this.float = top >= 50;
    },
    toRecharge() {
      this.$router.push({
        path: "/wallet",
        query: {
          flag: 1,
        },
      });
    },
    checkMore() {
      if (this.remainTimes === 0) {
        this.$message.messageError("当前刷新点数不足，请先充值");
      } else {
        this.checkMoreDialogVisible = false;
        this.ifDisplayAll = true;
        this.handleScheme();
      }
    },
    handleMore() {
      console.info("handleMoreFun", this.addRecord);
      this.getRemain();
      this.checkMoreDialogVisible = true;
    },
    closeCheckMoreDialog() {
      this.checkMoreDialogVisible = false;
    },
    getRemain() {
      const params = {
        userId: JSON.parse(localStorage.getItem("user")).userId,
      };
      getUserRemainingNumber(params).then((res) => {
        if (res.code === 0) {
          if (res.data !== null) {
            this.remainTimes = res.data.usableLimit;
          } else {
            this.remainTimes = 0;
          }
        } else {
          this.$message.messageError(res.message);
        }
      });
    },
    // 取消勾选专业
    cancleH() {
      this.changeTrue = false;
      this.professionDialogVisible = false;
      this.recover();
    },
    // 确定
    checkProfession() {
      const result = this.$refs.profession.getCheckedNodes();
      this.checkedNodes = result;
      this.profession = result.parentNames.map((item) => {
        const res = {
          name: item,
        };
        return res;
      });
      this.professionDialogVisible = false;
      this.changeTrue = false;
    },
    getUserInfo() {
      this.changeTrue = false;
      const params = {
        userId: JSON.parse(localStorage.getItem("user")).userId,
      };
      getUserInfoById(params)
        .then((res) => {
          if (res.code === 0) {
            if (!res.data.scoreInfo) {
              this.$message.messageError("您当前还未填写成绩信息，请先补全");
              return;
            }
            this.gradeType = res.data.gradeType;
            this.radio = res.data.gradeType === "专科" ? "1" : "2";
            this.cultureGrade = res.data.scoreInfo.cultureGrade || 0;
            this.majorGrade = res.data.scoreInfo.majorGrade || 0;
            const userData = {
              userId: res.data.id,
              ...res.data,
            };
            this.studentType = res.data.studentType;
            localStorage.setItem("user", JSON.stringify(userData));
            this.modifyProfession(false);
            setUserName(res.data.userName);
          } else {
            this.$message.messageError(res.message);
          }
        })
        .catch((error) => {
          this.$message.messageError(error.message);
        });
    },
    getRegionList() {
      ApiScheme.getRegionList()
        .then((res) => {
          if (res.code === 0) {
            this.RegionList = res.data;
          } else {
            this.$message.messageError(res.message);
          }
        })
        .catch((error) => {
          this.$message.messageError(error.message);
        });
    },
    // 获取
    handleScheme() {
      console.info("handleSchemeFun", this.addRecord);
      if (!JSON.parse(localStorage.getItem("user")).studentType) {
        this.$message.messageError("您当前还未填写成绩信息，请先补全");
        return;
      }
      if (this.profession.length === 0) {
        this.$message.messageError("请选择专业");
        return;
      }
      const user = JSON.parse(localStorage.getItem("user"));
      const params = {
        userId: user.id,
        gradeType: this.radio === "1" ? "专科" : "本科",
        cultureGrade: user.scoreInfo.cultureGrade,
        majorGrade: user.scoreInfo.majorGrade,
        bigMajorNames: this.checkedNodes.parentNames.join(","),
        professionIds: this.checkedNodes.childKeys.join(","),
        isShowAll: this.ifDisplayAll ? 1 : 0,
        region: this.checkList.join(","),
      };
      if (this.addRecord) {
        params.fullType = 1;
        params.recordId = localStorage.getItem("addRecordId");
        params.isShowAll = 1; // 1是查全部，0是查10条
      }
      ApiScheme.getSchemeList(params).then((res) => {
        if (res.code === 0) {
          if (this.ifDisplayAll) {
            this.currentRecordId = res.data.recordId;
          }
          this.schemlTable = res.data.volunteers;
          this.schemeShow = true;
          this.$nextTick(() => {
            document.getElementById("recommend").scrollIntoView();
          });
          if (res.message) {
            this.$message.messageSuccess(res.message);
          }
        } else {
          this.$message.messageError(res.message);
        }
      });
    },
    closeProfessionDialog() {
      this.professionDialogVisible = false;
    },
    modifyProfession(show = false, majorItem) {
      console.log('===========')
      console.log(majorItem) //majorItem.name
      // if(show) {
        this.changeTrue = show;
        let that = this
        if (majorItem) {
          //点击文字存在的时候--点击两次？
           this.$nextTick(() => { 
          that.$refs.profession.filterMajor(majorItem.name);
           })
        }
      // }
      if (majorItem) {
        this.tempItem = majorItem;
        if (majorItem.selected == 0 || majorItem.selected == undefined) {
          this.tempChecked = 0;
        }
        // if (this.majorSelectedCount >= 5 && majorItem.selected === 0) {
        //   return;
        // }
      }
      if (!JSON.parse(localStorage.getItem("user")).studentType) {
        this.$message.messageError("您当前还未填写成绩信息，请先补全");
        return;
      }
      if (this.changeGradeFlag) {
        console.log("这是什么Flag", this.changeGradeFlag);
        this.changeFlag = "true" + new Date().getTime();
        const params = {
          type: JSON.parse(localStorage.getItem("user")).studentType,
          gradeType: this.radio === "1" ? "专科" : "本科",
          userId: JSON.parse(localStorage.getItem("user")).userId,
        };
        getProfession(params).then((res) => {
          if (res.code === 0) {
            this.gradeList = res.data;
            this.professionDialogVisible = show;
            this.changeGradeFlag = false;
            this.snapshoot();
          }
        });
      } else {
        this.professionDialogVisible = true;
        // if (majorItem) {
        //   //点击文字存在的时候
        //   this.$refs.profession.filterMajor(majorItem.name);
        // }
        this.snapshoot();
        this.changeFlag = "false" + new Date().getTime();
      }
    },
    handleSelectionChange(val) {
      // console.info('选择插入的数据',val);
      Vue.prototype.mmval = val;
      this.selectedRecordList = val;
    },
    handleAdd() {
      if (this.selectedRecordList.length <= 0) {
        this.$message.messageError("需要勾选了才能添加");
        return;
      }
      this.$confirm("此操作将选中的记录插入到方案中, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 1：顺序上调，2：顺序下调
          const arr = [];
          // eslint-disable-next-line array-callback-return
          this.selectedRecordList.map((item) => {
            arr.push(item.id);
          });
          ApiScheme.addUserVolunteer({
            order: localStorage.getItem("schemeOrder"),
            schemeRecordId: localStorage.getItem("addRecordId"),
            volunteerId: arr.join(","),
            userId: JSON.parse(localStorage.getItem("user")).userId,
          }).then((res) => {
            if (res.code === 0) {
              localStorage.setItem("addRecordSuccess", true);
              this.$message.messageSuccess("插入成功!");
              localStorage.removeItem("schemeOrder");
              this.$router.push({path:"/programme",query:{mmval:['true']}});
            } else {
              this.$message.messageError(res.message);
            }
          });
        })
        .catch(() => {
          this.$message.messageSuccess("已取消插入");
          this.$router.push("/programme");
        });
    },
    goProgramme() {
      this.$router.push("/programme");
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .table .el-checkbox__inner {
    border: 1px solid #409eff;
}
/deep/ .el-dialog__header {
  padding: 8px 20px 5px;
}
/deep/ .el-tree[data-v-0bad6f9f] .el-tree-node  {
  margin-bottom: 0px;
}
.ppo {
  /deep/ .el-dialog__body {
    padding: 8px 8px;
  }
  /deep/ .el-dialog__footer {
    padding: 0px 20px 10px 20px;
  }
  /deep/ [data-v-7e37c8ec] .el-dialog__footer {
    border-top: none !important;
  }
  /deep/ .el-dialog__wrapper {
    position: fixed;
    top: 150px;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
  }
}
@media screen and (max-width: 1404px) {
  .abox {
    // justify-content: space-between;
    // display: flex;
  }
  .ppt {
    // width: 45%;
    // padding-left: 2.5%;
    // border-left: 2.5%;
    // box-sizing: border-box;
    // margin-left:0 !important;
  }
  // .syy {
  //   width: 100%;
  // }
  .syd {
  
    width: 48%;
  }
  .ppt {
 width: 100%;
}

}
  .abox {
    display: inline-flex;
    display: -webkit-flex; /* Safari */
    flex-wrap:wrap;
    justify-content:space-between;
  }

.syd {
  min-width: 350px;
 }
.ppt {
    min-width: 550px;

}
// .abox {
//   justify-content: space-between;
//   display: flex;
// }

.majorClass {
  display: flex;
  flex-flow: wrap; // 拆行显示
  font-size: 14px;
  color: rgb(51, 51, 51);
  .marjorName {
    margin-right: 7px;
    white-space: nowrap;
    .text {
      cursor: pointer;
      font-size: 14px;
      color: #409eff;
    }
  }
}
.home {
  margin-top: -60px;
  min-width: 678px;
}
.profession-content {
  display: flex;
  flex-wrap: wrap;
  // width: 76%;
  width: 88%;
}
// .el-table td, .el-table th.is-leaf {
//     border-bottom: 1px solid #EBEEF5;
//     padding: 0px 0px;
// }
/deep/ .el-table td, .el-table th {
    padding: 9px 0 !important;
}
.xinxin{
    display: flex;
    font-size: 35px;
    text-align: center;
    align-items: center;
    margin-top: 16px;
}
.content > .tip {
  text-align: start;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
  color: #666666;
}
.bottom-btn {
  width: 240px;
  height: 48px;
  margin: 5px auto 25px;
}

.boldFont {
  width: 90px;
}
.boldFont1 {
  width: 90px;
  font-size: 20px;
  font-weight: 700;
}

.table /deep/ .el-table__body-wrapper {
  height: 408px;
  overflow-y: auto;
}

.float-ad {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin-bottom: 20px;
  z-index: 3;
}
.dialog-footer {
  .el-button {
    width: 120px;
  }
}

.foot-police {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px !important;
  img {
    width: 14px;
    height: 14px;
  }
  a {
    text-decoration: none;
    color: #666;
  }
  a:hover {
    text-decoration: underline;
    text-decoration-color: #666;
  }
}
.el-checkbox__label {
  font-size: 18px;
}
.el-checkbox {
  margin-right: 7px;
}
</style>

<style>
/* 表格偏移对不齐 */
.el-table th.gutter {
  display: table-cell !important;
}
</style>
