import request from '@/utils/request';

/**
 * 获取广告
 * @param {*} type 1 顶部 / 2 底部
 */
export default function getBanner(type) {
  return request({
    url: `/banner/getBanners?type=${type}`,
    method: 'get',
  });
}
