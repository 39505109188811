<template>
  <div class="banner" :style="{height:bannerHeight}">
    <base-carousel :imageList="bannerList" />
  </div>
</template>

<script>
import getBanner from '@/api/banner';
import BaseCarousel from '@/components/BaseCarousel/index.vue';

export default {
  name: 'Banner',
  components: {
    BaseCarousel,
  },
  data() {
    return {
      bannerList: [],
    };
  },
  computed: {
    bannerHeight() {
      const { clientWidth } = document.body;
      // eslint-disable-next-line no-mixed-operators
      const height = clientWidth * 380 / 1920;
      return height + 'px';
    },
  },
  mounted() {
    this.getBannerList();
  },
  methods: {
    getBannerList() {
      getBanner(1).then((res) => {
        if (res.code === 0) {
          this.bannerList = res.data.map((item) => {
            const temp = {
              url: process.env.VUE_APP_IMAGE_API + item.image,
              ...item,
            };
            return temp;
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
